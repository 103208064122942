import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Divider } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import qtravel from "images/qtravel.svg";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { HOME_PATH } from "../../../config/routes";
import useAuth from "../../../hooks/useAuth";
import amadeus from "../../../images/amadeus.png";
import atlas_tours from "../../../images/atlas_tours.jpeg";
import baboo from "../../../images/baboo-logo.svg";
import best_reisen from "../../../images/best_reisen.png";
import coral from "../../../images/coral.png";
import explore from "../../../images/explore-logo.svg";
import frather from "../../../images/frater.png";
import icon from "../../../images/icons/search-solid.svg";
import on_holiday from "../../../images/on_holiday.png";
import prima_holiday from "../../../images/prima-logo.png";
import rainbow from "../../../images/rainbow.png";
import tanierejsowanie from "../../../images/tanierejsowanie.png";
import tourhub from "../../../images/tourhub.png";
import volare_travel from "../../../images/volare_travel.png";
import wygoda_travel from "../../../images/wygoda_logo.png";
import { Button } from "../../Base";
import styles from "./OfferHeader.module.scss";

const OfferHeader = ({ searchQuery }) => {
  const { t } = useTranslation();
  const { logout } = useAuth();
  const partner = window.localStorage.getItem("partnerSlug");
  const [anchorElUser, setAnchorElUser] = useState(null);

  let [query, setQuery] = useState(searchQuery);

  const triggerSearch = () => {
    window.location.href = `/offers?query=${encodeURIComponent(query)}`;
  };
  const handleKeyDown = (event) => (event.key === "Enter" ? triggerSearch() : false);
  const handleChange = (event) => setQuery(_.trim(event.target.value, "\n"));
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  useEffect(() => {
    setQuery(_.trim(searchQuery));
  }, [searchQuery]);

  const logos = {
    qtravel: qtravel,
    baboo_travel: baboo,
    explore_co_uk: explore,
    rainbow: rainbow,
    coral_travel_pl: coral,
    coral_travel_lv: coral,
    coral_travel_lt: coral,
    coral_travel_ee: coral,
    prima_holiday: prima_holiday,
    tanierejsowanie: tanierejsowanie,
    amadeus_en: amadeus,
    amadeus_pl: amadeus,
    best_reisen: best_reisen,
    wygoda_travel: wygoda_travel,
    on_holiday: on_holiday,
    volare_travel: volare_travel,
    atlas_tours: atlas_tours,
    frather: frather,
    tourhub: tourhub,
  };

  return (
    <>
      <header className={styles.headerNav}>
        <Box className={styles.headerWrapper}>
          <Box className={styles.logoWrapper}>
            {partner && partner !== "demo_en" && partner !== "demo_pl" && (
              <>
                <img src={logos[partner] || qtravel} className={styles.partnerLogo} />
                <span className={styles.poweredBy}>{t("main.powered_by")}</span>
              </>
            )}
            <Link to={HOME_PATH}>
              <img src={logos.qtravel} alt="Qtravel.ai" />
            </Link>
          </Box>
          <div className={styles.searchBox}>
            <textarea
              name="searchTerm"
              value={query || ""}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              className={styles.inputSearch}
              required
            />
            <img src={icon} alt="Search" onClick={triggerSearch} className={styles.searchIcon}></img>
          </div>
          <Tooltip title={t("main.openSettings")}>
            <Button
              size="large"
              endIcon={<KeyboardArrowDownIcon />}
              id="menu_logout_btn"
              variant="outlined"
              bordercolor="white"
              color="white"
              onClick={handleOpenUserMenu}
            >
              {partner}
            </Button>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem onClick={() => logout({ returnTo: window.location.origin })}>{t("main.logout")}</MenuItem>
            <Divider />
            <MenuItem onClick={() => window.open(`${process.env.REACT_APP_DEVELOPER_URL}/?session=init`)}>
              {t("main.playground")}
            </MenuItem>
            <MenuItem onClick={() => window.open(`${process.env.REACT_APP_DASHBOARD_URL}/?session=init`)}>
              {t("main.dashboard")}
            </MenuItem>
          </Menu>
        </Box>
      </header>
    </>
  );
};

export default OfferHeader;
